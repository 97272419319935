<template>
    <div>
        <button @click="prevPage" :disabled="currentPage === 1">Previous</button>
        <span>{{ currentPage }} De {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages">Next</button>
    </div>
</template>

<script>
export default {
    props: {
        currentPage: Number,
        totalPages: Number,
    },
    methods: {
        prevPage() {
            if (this.currentPage > 1) {
                this.$emit('page-changed', this.currentPage - 1);
            }
        },
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.$emit('page-changed', this.currentPage + 1);
            }
        },
    },
};
</script>
<style>
.clearfix {
    text-align: right;
    padding: 0 20px 20px 0;
}
.clearfix button {
    background: #405189;
    color: white;
    padding: 6px 19px;
    font-size: 12px;
    border-radius: 5px;
    border: 0;
}
.clearfix span {
    margin-left: 5px;
    margin-right: 5px;
    background: cornflowerblue;
    padding: 5px;
    color: white;
    border-radius: 5px;
}
</style>