<template>
<div v-if="!isLoading"></div>
  <div v-else>Chargement...</div>
<div class="login-box">
  <!-- /.login-logo -->
  <div class="card card-outline card-primary">
    <div class="card-header text-center">
      <a href="../../index2.html" class="h1"><b>FLSH</b>M</a>
    </div>
    <div class="card-body">
        <img src="../../assets/logo.png" width="100%" class="mb-3" alt="">

      <form @submit.prevent="submit()" method="post">
        <div class="input-group mb-3">
          <input v-model="signIn.email" @blur="v$.signIn.email.$touch" :class="{ error: v$.signIn.email.$errors.length }" type="email" class="form-control" placeholder="Email">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-envelope"></span>
            </div>
          </div>
          
        </div>
        <div class="input-errors" v-for="error of v$.signIn.email.$errors" :key="error.$uid">
    <span class="error-msg">{{ error.$message }}</span>
  </div>
        <div class="input-group mb-3">
          <input  v-model="signIn.password" @blur="v$.signIn.password.$touch" :class="{ error: v$.signIn.password.$errors.length }" type="password" class="form-control" placeholder="Password">
          <div class="input-group-append">
            <div class="input-group-text">
              <span class="fas fa-lock"></span>
            </div>
          </div>
          
  
        </div>
        <div class="input-errors" v-for="error of v$.signIn.password.$errors" :key="error.$uid">
    <span class="error-msg">{{ error.$message }}</span>
  </div>
  <div class="input-errors" style="text-align: left;" v-if="getErrorAuth">
    <span class="error-msg">{{ getErrorAuth }}</span>
  </div>
        <div class="row">
          <!--<div class="col-8">
            <div class="icheck-primary">
              <input type="checkbox" id="remember">
              <label for="remember">
                Remember Me
              </label>
            </div>
          </div>
           /.col -->
           
          <div class="col-12">
            <button type="submit" class="btn btn-primary btn-block">Se connecter</button>
          </div>
          <!-- /.col -->
        </div>
      </form>

      <!--<div class="social-auth-links text-center mt-2 mb-3">
        <a href="#" class="btn btn-block btn-primary">
          <i class="fab fa-facebook mr-2"></i> Sign in using Facebook
        </a>
        <a href="#" class="btn btn-block btn-danger">
          <i class="fab fa-google-plus mr-2"></i> Sign in using Google+
        </a>
      </div>
       /.social-auth-links -->

      <!--<p class="mb-1">
        <a href="forgot-password.html">I forgot my password</a>
      </p>
      <p class="mb-0">
        <a href="register.html" class="text-center">Register a new membership</a>
      </p>-->
    </div>
    <!-- /.card-body -->
  </div>
  <!-- /.card -->
</div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength, maxLength, sameAs } from '@vuelidate/validators'
import { mapActions, mapGetters } from "vuex";
export default {
    data() {
    return {
        v$: useVuelidate(),
        signIn: {
        email: '',
        password: ''
      }
    }
  },
  validations() {
    return {
        signIn: {
            password: {
                required,
                minLength: minLength(5),
                maxLength: maxLength(30),
                $autoDirty: true,
            },
            email: { 
                required,
                email,
                minLength: minLength(10),
                maxLength: maxLength(40),
                $autoDirty: true,
            }
        },
    };
},
    methods: {
        ...mapActions({
            login: "Auth/signIn",
        }),
        submit() {
            this.login(this.signIn);
        },
    clearFormData() {
        this.signIn = {
        email: '',
        password: ''
      },
      this.v$.$reset();
    }
    },
    computed: {
        ...mapGetters({
            getErrorAuth: "Auth/getErrorAuth",
        }),
    },
  mounted() {
    document.body.classList.add('hold-transition', 'login-page');
  },
  beforeDestroy() {
    console.log('beforeDestroy');
    document.body.classList.remove('hold-transition', 'login-page');
  },
  beforeRouteLeave(to, from, next) {
    console.log('beforeRouteLeave');
    document.body.classList.remove('hold-transition', 'login-page');
    next();
  }
}

</script>
<style scoped>

</style>