<template>
  <div v-if="!isLoading">
    <CoverHeaderRevue />
    <Navbar />

    <div class="container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="card mb-3">
            <div class="row g-0">
              <div class="col-md-4">
                <img
                  v-if="revue.image"
                  :src="imagePath + revue.image"
                  class="img-fluid rounded-start m-2"
                  alt="..."
                />
                <img
                  v-else
                  :src="imagePath + 'pngtree-loading.png'"
                  class="img-fluid rounded-start m-2"
                  alt="..."
                />
              </div>
              <div class="col-md-8">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-6">
                      <h5 class="card-title policefr">
                        Revue de Recherches en Sciences Humaines et Sociales
                      </h5>
                    </div>
                    <div class="col-md-6">
                      <h5
                        class="card-title policear"
                        style="text-align: end !important"
                      >
                        مجلة بحوث في العلوم الإنسانية والإجتماعية
                      </h5>
                    </div>
                  </div>

                  <p class="card-text mt-5 policefr">
                    <span style="font-weight: bold"
                      >Directeur de la revue :
                    </span>
                    {{ revue.directeur }}
                  </p>
                  <p class="card-text policefr">
                    <span style="font-weight: bold">Numéro : </span>
                    {{ revue.numero }}
                  </p>
                  <p v-if="isArabic(revue.title)" class="card-text policear">
                    <span style="font-weight: bold">Titre : </span>
                    {{ revue.title }}
                  </p>
                  <p v-else class="card-text policefr">
                    <span style="font-weight: bold">Titre : </span>
                    {{ revue.title }}
                  </p>
                  <p class="card-text policefr">
                    <span style="font-weight: bold">Coordination : </span>
                    {{ revue.coordinateur }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sommaire">
        <div class="row justify-content-center m-3">
          <div
            class="col-sm-12 col-md-8 content mb-4"
            v-for="item in revue?.articles"
          >
            <div class="row">
              <div class="col-md-4 left" style="position: relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-file-earmark-fill img-art"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2z"
                  />
                </svg>
                <span
                  class="policefr"
                  style="
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 100%;
                    text-align: center;
                    color: white;
                    font-size: 18px;
                  "
                  >Pages : <br />{{ item.titre }}</span
                >
              </div>
              <div class="col-md-8 right">
                <h6 class="pages">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    style="color: #20c997;
    margin-bottom: 7px;
    margin-right: 6px;"
                    fill="currentColor"
                    class="bi bi-file-earmark-fill"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2m5.5 1.5v2a1 1 0 0 0 1 1h2z"
                    /></svg
                  >Pages : {{ item.titre }}
                </h6>
                <hr class="pages">
                <div class="intro">
                  <h2
                    v-if="isArabic(item.description)"
                    class="policear"
                    style="text-align: right"
                  >
                    {{ item.description }}
                  </h2>
                  <h2 v-else class="policefr">{{ item.description }}</h2>
                  <h3 v-for="per in item?.auteurs" class="mr-2 ml-2">
                    <span
                      style="font-size: 21px"
                      v-if="isArabic(item.description)"
                      class="policear"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style="margin-bottom: 3px; margin-right: 5px"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-person-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                        <path
                          fill-rule="evenodd"
                          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                        />
                      </svg>
                      {{ per.nom_prenom }}
                    </span>
                    <span v-else class="policefr">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        style="margin-bottom: 3px; margin-right: 5px"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-person-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                        <path
                          fill-rule="evenodd"
                          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"
                        />
                      </svg>
                      {{ per.nom_prenom }}
                    </span>
                  </h3>
                  <div class="button">
                    <a
                      target="_blank"
                      :href="imagePath + item.fichier"
                      type="button"
                      class="btn btn-dowload policefr"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-arrow-down-square"
                        style="margin-right: 4px"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M15 2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V2zM0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2zm8.5 2.5a.5.5 0 0 0-1 0v5.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V4.5z"
                        ></path>
                      </svg>
                      TELECHARGER
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </div>
  <div v-else><loding /></div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
const Path = "Numero/";
export default {
  data() {
    return {
      revue: {},
    };
  },
  watch: {
    "$route.params.slug": {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.OneFetch();
        }
      },
    },
  },
  methods: {
    isArabic(text) {
      const arabicRegex = /[\u0600-\u06FF]/;
      return arabicRegex.test(text);
    },
    ...mapActions({
      fetchOne: Path + "fetchOne",
    }),
    async OneFetch() {
      const slug = this.$route.params.slug;
      try {
        const result = await this.fetchOne(slug);
        this.revue = result.response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    imagePath() {
      return this.$store.state.imagePath;
    },
    ...mapGetters({
      isLoading: Path + "isLoading",
    }),
  },
  mounted() {},
};
</script>
<style scoped>
.img-art {
  width: 48%;
  height: 181px;
  opacity: 0.8;
  color: #20c997;
}

.content {
  box-shadow: 0 7px 7px rgba(0, 0, 0, 0.18);
  padding: 17px 31px;
}
.numero {
  font-weight: 700;
  color: white;
  background: cadetblue;
  padding: 8px;
}
.text {
  font-size: 20px;
  text-align: justify;
  margin-top: 13px;
  margin-bottom: 13px;
}
.per {
  font-weight: 700;
  font-size: 18px;
  text-align: left;
}
.btn-dowload {
  background: var(--bs-teal);
  color: white;
  border-radius: 0;
  border-radius: 21px;
  font-size: 11px;
  margin-top: 10px;
}
.button {
  text-align: left;
}

.sommaire .right {
  text-align: left;
}
.sommaire .right h2 {
  font-size: 18px;
}
.sommaire .right h3 {
  font-size: 18px;
  font-weight: bold;
  color: brown;
}
.sommaire .left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 23px;
}

.card {
  border: 0;
  background: #81c6ef17;
  padding: 27px;
}
img {
  height: 400px;
  width: 80%;
  text-align: left;
}
.card-body {
  text-align: left;
}
.card-title {
  font-weight: bold;
  font-size: 25px;
  color: #ff0000;
}
.card-text {
  font-size: 20px;
}
.sommaire .pages {
  display: none;
}

@media screen and (max-width: 475px) {
}

@media (min-width: 1200px) {
  /* Styles pour les écrans larges */
}
@media (min-width: 768px) and (max-width: 1199px) {
  /* Styles pour les tablettes */
}
@media (max-width: 767px) {
  .card-title {
    font-size: 21px;
    text-align: center;
  }
  .card-text {
    font-size: 16px;
  }

  .sommaire .left {
    padding: 7px;
    margin-bottom: 14px;
  }
  .sommaire .right h2 {
    font-size: 15px;
  }
  .card-title {
    text-align: center !important;
  }
  img {
    height: 295px;
  }
  .sommaire .left {
    display: none;
  }
  .sommaire .pages {
    display: block;
  }
}
</style>
