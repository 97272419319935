import { createStore } from 'vuex'
import AuthModule from "./Auth";
import UserModule from "./User";
import RevueModule from "./Revue";
import CategoryModule from "./Category";
import ComiteModule from "./Comite";
import NumeroModule from "./Numero";
import AuteurModule from "./Auteur";
import ArticleModule from "./Article";
import ColloqueModule from "./Colloque";
import ConferenceModule from "./Conference";

export default createStore({
  state: {
    imagePath: 'https://revue.recherche-scientifique-flshm.ma/revue/storage/app/public/images/',
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    Auth: AuthModule,
    User: UserModule,
    Revue: RevueModule,
    Category: CategoryModule,
    Comite: ComiteModule,
    Numero: NumeroModule,
    Auteur: AuteurModule,
    Article: ArticleModule,
    Colloque: ColloqueModule,
    Conference: ConferenceModule,
  }
})
