<template>
  <div v-if="!isLoading">
    <CoverHeaderRevue />
  <Navbar />

  <div class="container">
    <div class="row">
      <div class="col-md-12 title-top policear">
        <h3 dir="rtl">
          تهدف مجلة بحوث في العلوم الإنسانية والإجتماعية للمساهمة بالنشر العلمي
          في مجالات الآداب والعلوم الإنسانية والاجتماعية بأبعاد موضوعاتية متنوعة
          باللغة العربية والفرنسية والإنجليزية والإسبانية.
        </h3>
      </div>
    </div>
    <div class="row pt-0 pb-0">
      <div class="col-md-12 text-center">
        <div class="title-ar policear" dir="rtl">
          دعوة للنشر في مجلة بحوث في العلوم الإنسانية والإجتماعية.
        </div>
        <div class="text-appel policefr"
        >
          Appel à publication.
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div v-for="item in getData.data" :key="item.slug" class="col-md-6">
        <router-link
          v-if="isArabic(item.titre)"
          :to="{ name: 'revue-recherche-one', params: { slug: item.slug } }"
          class="text-decoration-none policear" 
        >
          <div class="card dark">
            <div class="card-body">
              <div class="cta-section">
                <div></div>

                <router-link
                  :to="{
                    name: 'revue-recherche-one',
                    params: { slug: item.slug },
                  }"
                  class="btn btn-light"
                  ><svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-right-circle-fill mr-1"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"
                    /></svg
                  >المزيد</router-link
                >
              </div>
              <div class="text-section text-end">
                <h6 class="card-title mb-0">
                  {{
                    item.titre?.length > 30
                      ? "..." + item.titre.substring(0, 30)
                      : item.titre
                  }}
                </h6>
                <p class="card-text">
                  {{ item.coordinateur }}
                </p>
              </div>
            </div>
            <img
              :src="imagePath + item.image"
              class="card-img-top"
              alt="..."
            /></div
        ></router-link>
        <router-link
          v-else
          :to="{ name: 'revue-recherche-one', params: { slug: item.slug } }"
          class="text-decoration-none policefr"
          ><div class="card dark">
            <img :src="imagePath + item.image" class="card-img-top" alt="..." />
            <div class="card-body">
              <div class="text-section">
                <h6 class="card-title mb-0">
                  {{
                    item.titre?.length > 30
                      ? item.titre.substring(0, 30) + "..."
                      : item.titre
                  }}
                </h6>
                <p class="card-text">
                  {{ item.coordinateur }}
                </p>
              </div>
              <div class="cta-section">
                <div></div>

                <router-link
                  :to="{
                    name: 'revue-recherche-one',
                    params: { slug: item.slug },
                  }"
                  class="btn btn-light"
                  >Plus<svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-arrow-right-circle-fill ml-1"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0M4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5z"
                    /></svg
                ></router-link>
              </div>
            </div></div
        ></router-link>
      </div>
    </div>
  </div>

  <Footer />
</div>
<div v-else>
<loding />
  </div>
  
</template>
<script>
import { mapActions, mapGetters } from "vuex";
const Path = "Revue/";
export default {
  data() {
    return {
      revue: [],
    };
  },
  methods: {
    isArabic(text) {
      const arabicRegex = /[\u0600-\u06FF]/;
      return arabicRegex.test(text);
    },
    ...mapActions({
      fetch: Path + "fetch",
    }),
  },
  computed: {
    ...mapGetters({
      getData: Path + "getData",
      isLoading: Path + "isLoading"
    }),
    imagePath() {
      return this.$store.state.imagePath;
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
<style scoped>
  .text-appel {
            font-size: 22px;
            font-weight: bold;
  }
.title-top h3 {
  background: cadetblue;
  color: white;
  padding: 17px;
  border-radius: 20px;
  font-size: 18px;
    line-height: normal;
}
.card {
  max-width: 30em;
  flex-direction: row;
  border: 0;
  box-shadow: 0 7px 7px rgba(0, 0, 0, 0.18);
  margin: 1em auto;
}
.card.dark {
  color: #6c757d;
}
.card.card.bg-light-subtle .card-title {
  color: dimgrey;
}

.card img {
  max-width: 25%;
  margin: auto;
  border-radius: 0.7em;
  padding: 18px;
  /*height: 119px;*/
}
.card-body {
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.text-section {
  max-width: 77%;
}
.cta-section {
  max-width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.cta-section .btn {
  padding: 0.3em 0.5em;
  /* color: #696969; */
}
.card.bg-light-subtle .cta-section .btn {
  background-color: #898989;
  border-color: #898989;
}


.title-ar {
  font-size: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
}
.cta-section .btn {
  background: #81c6ef ;
  color: white ;
  font-family: monospace ;
}
.card-title {
  font-weight: bold;
  font-size: 18px;
}
.card-text {
  font-size: 15px;
}
@media screen and (max-width: 475px) {
  .card {
    font-size: 0.9em;
  }
}

@media (min-width: 1200px) {
  /* Styles pour les écrans larges */
}
@media (min-width: 768px) and (max-width: 1199px) {
  /* Styles pour les tablettes */
}
@media (max-width: 767px) {
  .title-top h3 {
    font-size: 16px;
  }
  .text-end,
  .text-start {
    text-align: center;
  }
  .title-fr {
    font-size: 29px;
  }
  .title-ar {
    font-size: 23px;
  }
  .card-title {
    font-size: 16px;
  }
  .card-text {
    font-size: 15px;
  }
  .cta-section {
    display: none;
  }
  .text-section {
    max-width: 100%;
  }
  .card img {
    padding: 13px;
  }
  .card {
    font-size: 0.9em;
    padding-left: 13px;
  }
}
</style>
