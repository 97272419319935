import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';

require("@/store/subscriber");
//axios.defaults.baseURL = 'http://localhost:8000/api';
axios.defaults.baseURL = 'https://revue.recherche-scientifique-flshm.ma/api';


// import Front
import Footer from "./components/FrontOffice/Home/Footer.vue";
import Navbar from "./components/FrontOffice/Home/Navbar.vue";
import loding from "./components/FrontOffice/loding.vue";
import CoverHeader from "./components/FrontOffice/Home/CoverHeader.vue";
import CoverHeaderColloques from "./components/FrontOffice/Home/CoverHeaderColloques.vue";
import CoverHeaderConferences from "./components/FrontOffice/Home/CoverHeaderConferences.vue";
import CoverHeaderRevue from "./components/FrontOffice/Home/CoverHeaderRevue.vue";

// import Back
import Aside from "./components/BackOffice/Aside.vue";
import Nav from "./components/BackOffice/Nav.vue";
import FooterBack from "./components/BackOffice/FooterBack.vue";
import Pagination from "./components/BackOffice/Pagination.vue";





store.dispatch("Auth/attempt", localStorage.getItem("token")).then(() => {
    createApp(App)
    .use(store)
    .use(router)
    .component("Navbar", Navbar)
    .component("loding", loding)
    .component("Footer", Footer)
    .component("CoverHeader", CoverHeader)
    .component("CoverHeaderColloques", CoverHeaderColloques)
    .component("CoverHeaderConferences", CoverHeaderConferences)
    .component("CoverHeaderRevue", CoverHeaderRevue)
    .component("Aside", Aside)
    .component("Nav", Nav)
    .component("FooterBack", FooterBack)
    .component("Pagination", Pagination)
    .mount('#app')
});


