<template>

    <CoverHeaderRevue />
    <Navbar />







    <Footer />
</template>
<style scoped>




@media (min-width: 1200px) {
  /* Styles pour les écrans larges */
}
@media (min-width: 768px) and (max-width: 1199px) {
  /* Styles pour les tablettes */
}
@media (max-width: 767px) {

    
}



</style>