<template>
  <CoverHeader />
  <div class="card-category container p-5">
    <div class="row">
      <div
        class="col-12 col-md-6 col-lg-4"
        v-for="(card, index) in cards"
        :key="card.id"
        :class="getOrderClass(index)"
      >
        <div class="card">
          <router-link :to="card.link">
            <img
              :src="imagePath + card.imagePath"
              class="card-img-top"
              alt="..."
            />
          </router-link>
          <div class="card-body">
            <h5 style="color: cornflowerblue" class="card-title policear">
              {{ card.title }}
            </h5>
            <h6 style="color: cornflowerblue" class="card-title policear">
              {{ card.desTitle }}
            </h6>
            <h5 style="color: darkblue" class="card-title policefr">
              {{ card.subtitle }}
            </h5>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</template>
<script>
export default {
  data() {
    return {
      cards: [
        {
          id: 1,
          imagePath: "university-student-123456789.jpg",
          title: "دروس و محاضرات إفتتاحية",
          desTitle: "",
          subtitle: "Conférences et leçons inaugurales".toUpperCase(), //CONFRENCES ET LECONS INAUGURALES
          link: { name: "conferences" },
        },
        {
          id: 2,
          imagePath: "colloque_0123456789.jpg",
          title: "مؤلفات",
          desTitle: "( ... ندوات، مؤلف جماعي )",
          subtitle: "COLLOQUES, LIVRES COLLECTIFS, ...",
          link: { name: "colloques" },
        },
        {
          id: 3,
          imagePath: "rptgtpxd-1396254731.jpg",
          title: "مجلة البحوث في العلوم الإنسانية و الإجتماعية",
          desTitle: "",
          subtitle: "REVUE DE RECHERCHES EN SCIENCES HUMAINES ET SOCIALES",
          link: { name: "revue-recherche" },
        },
      ],
    };
  },
  methods: {
    getOrderClass(index) {
      const orderClasses = ["order-3", "order-2", "order-1"];
      return orderClasses[index] || "";
    },
  },
  computed: {
    imagePath() {
      return this.$store.state.imagePath;
    },
  },
  mounted() {},
};
</script>
<style scoped>
.card {
  border: none;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: 0.5s;
  width: 100%;
}
.card-title {
  font-size: 18px;
}
.card-body {
  height: 158px;
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.card img {
  height: 280px;
}
.card:hover {
  scale: 1.1;
  z-index: 1000;
}
@media (min-width: 1200px) {
  /* Styles pour les écrans larges */
}
@media (min-width: 768px) and (max-width: 1199px) {
  /* Styles pour les tablettes */
}
@media (max-width: 932px) {
  .card {
    margin-bottom: 30px;
  }

  .card-title {
    font-size: 18px;
  }
  .card-body {
    height: 187px;
  }
  .card:hover {
    scale: 1.1;
    z-index: 1000;
  }

  .card img {
    height: 238px;
  }
}
</style>
