import axios from "axios";

const API_BASE_URL = "/categories";
const MESSAGES_SUCCES = {
  creerUtilisateur: "créé avec succès",
  miseAJour: "Mise à jour effectuée",
};

export default {
  namespaced: true,
  state: {
    data: [],
    dataAll: [],
    dataOne: {},
    currentPage: 1,
    totalPages: 0,
    isLoading: false,
  },
  mutations: {
    setCurrentPage(state, currentPage) {
      state.currentPage = currentPage;
  },
  setTotalPages(state, totalPages) {
      state.totalPages = totalPages;
  },
    setData(state, data) {
      state.data = data;
    },
    setDataAll(state, dataAll) {
      state.dataAll = dataAll;
    },
    setOneData(state, dataOne) {
      state.dataOne = dataOne;
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  getters: {
    getData: (state) => state.data,
    getDataAll: (state) => state.dataAll,
    getCurrentPage: (state) => state.currentPage,
    getTotalPages: (state) => state.totalPages,
    isLoading: (state) => state.isLoading,
  },  
  actions: {
    async fetch({ commit, state }, params) {
      commit('setLoading', true);
      try {
        const response = await axios.get(
          `${API_BASE_URL}?page=${state.currentPage}&search=${params || ""}`
        );
        commit("setData", response.data);
        if(response.data.meta && response.data.meta.last_page) {
          commit("setTotalPages", response.data.meta.last_page);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        commit('setLoading', false); // Fin du chargement
      }
    },
    async fetchAll({ commit, state }) {
      commit('setLoading', true);
      try {
        const response = await axios.get(
          `${API_BASE_URL}/all`
        );
        commit("setDataAll", response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        commit('setLoading', false); // Fin du chargement
      }
    },
    async fetchOne({ commit, state }, params) {
      commit('setLoading', true);
      try {
        const response = await axios.get(`${API_BASE_URL}/${params}`);
        const item = response.data.data;
      commit('setOneData', item);
      return { success: true, response };
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        commit('setLoading', false); // Fin du chargement
      }
    },
    changePage({ commit, dispatch }, newPage) {
        commit('setCurrentPage', newPage);
        dispatch('fetch');
    },
    async store({ commit, dispatch }, params) {
      try {
        const response = await axios.post(API_BASE_URL, params);
        commit("setData", response.data);
        dispatch("showSuccessMessage", MESSAGES_SUCCES.creerUtilisateur);
        return { success: true, response };
      } catch (error) {
        // Handle registration error
        if (error.response.status === 422 && error.response.data.error === 'Email already exists') {
          console.log('This email is already taken. Please choose another one.');
      } else {
          console.log('An error occurred during registration.');
      }
      }
    },
    showSuccessMessage(_, message) {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
    },  
    async update({ dispatch }, params) {
      try {
        console.log(params)
        const response = await axios.put(`${API_BASE_URL}/${params.id}`,params);
        dispatch("showSuccessMessage", MESSAGES_SUCCES.miseAJour);
        return { success: true, response };
      } catch (error) {
        console.error(error);
      }
    },
    async destroy({ dispatch }, params) {
      try {
        const result = await Swal.fire({
          title: "Êtes-vous sûr(e) ?",
          text: "Vous ne pourrez pas revenir en arrière !",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, supprimez-le !",
        });
        if (result.isConfirmed) {
          await axios.delete(`${API_BASE_URL}/${params}`);
          Swal.fire("Supprimé !", "Votre fichier a été supprimé.", "success");
        }
        return { success: true, result };
      } catch (error) {
        console.error(error);
      }
    },
  },
  modules: {},
};
