import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
//import HomeView from '../views/HomeView.vue'

import Home from '../views/FrontOffice/Home.vue'
import RevueRecherche from '../views/FrontOffice/RevueRecherche.vue'
import RevueRecherchePage from '../views/FrontOffice/RevueRecherchePage.vue'
import Numero from '../views/FrontOffice/Numero.vue'
import OneNumero from '../views/FrontOffice/OneNumero.vue'
import RevueRecherchePageOne from '../views/FrontOffice/RevueRecherchePageOne.vue'
import Comites from '../views/FrontOffice/Comites.vue'
import Login from '../views/FrontOffice/Login.vue'
import Recherche from '../views/FrontOffice/Recherche.vue'
import Conferences from '../views/FrontOffice/Conferences.vue'
import Colloques from '../views/FrontOffice/Colloques.vue'

import Dashboard from '../views/BackOffice/Dashboard.vue'
import User from '../views/BackOffice/User.vue'
import Revue from '../views/BackOffice/Revue.vue'
import Category from '../views/BackOffice/Category.vue'
import Comite from '../views/BackOffice/Comite.vue'
import NumeroBack from '../views/BackOffice/Numero.vue'
import Article from '../views/BackOffice/Article.vue'
import Auteur from '../views/BackOffice/Auteur.vue'
import Conference from '../views/BackOffice/Conference.vue'
import Colloque from '../views/BackOffice/Colloque.vue'



const routes = [
  {
    path: '/dashboard',
    redirect: '/dashboard/utilisateurs',
    name: 'dashboard',
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if( !store.getters["Auth/authenticated"] ) {
        return next({ name : 'login' })
      }
      next();
    }
  },
  {
    path: '/dashboard/utilisateurs',
    name: 'utilisateurs',
    component: User,
    beforeEnter: (to, from, next) => {
      if( !store.getters["Auth/authenticated"] ) {
        return next({ name : 'login' })
      }
      next();
    }
  },
  {
    path: '/dashboard/revue',
    name: 'revue',
    component: Revue,
    beforeEnter: (to, from, next) => {
      if( !store.getters["Auth/authenticated"] ) {
        return next({ name : 'login' })
      }
      next();
    }
  },
  {
    path: '/dashboard/categorie',
    name: 'categorie',
    component: Category,
    beforeEnter: (to, from, next) => {
      if( !store.getters["Auth/authenticated"] ) {
        return next({ name : 'login' })
      }
      next();
    }
  },
  {
    path: '/dashboard/comite',
    name: 'comite',
    component: Comite,
    beforeEnter: (to, from, next) => {
      if( !store.getters["Auth/authenticated"] ) {
        return next({ name : 'login' })
      }
      next();
    }
  },
  {
    path: '/dashboard/numero',
    name: 'number',
    component: NumeroBack,
    beforeEnter: (to, from, next) => {
      if( !store.getters["Auth/authenticated"] ) {
        return next({ name : 'login' })
      }
      next();
    }
  },
  {
    path: '/dashboard/article',
    name: 'article',
    component: Article,
    beforeEnter: (to, from, next) => {
      if( !store.getters["Auth/authenticated"] ) {
        return next({ name : 'login' })
      }
      next();
    }
  },
  {
    path: '/dashboard/conference',
    name: 'conference',
    component: Conference,
    beforeEnter: (to, from, next) => {
      if( !store.getters["Auth/authenticated"] ) {
        return next({ name : 'login' })
      }
      next();
    }
  },
  {
    path: '/dashboard/colloque',
    name: 'colloque',
    component: Colloque,
    beforeEnter: (to, from, next) => {
      if( !store.getters["Auth/authenticated"] ) {
        return next({ name : 'login' })
      }
      next();
    }
  },
  {
    path: '/dashboard/auteur',
    name: 'auteur',
    component: Auteur,
    beforeEnter: (to, from, next) => {
      if( !store.getters["Auth/authenticated"] ) {
        return next({ name : 'login' })
      }
      next();
    }
  },



  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/recherche',
    name: 'recherche',
    component: Recherche
  },
  {
    path: '/conferences',
    name: 'conferences',
    component: Conferences
  },
  {
    path: '/colloques',
    name: 'colloques',
    component: Colloques
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/revue-recherche-sciences-humaines-sociales',
    name: 'revue-recherche-sciences-humaines-sociales',
    component: RevueRecherche
  },
  {
    path: '/revue-recherche-generale',
    name: 'revue-recherche',
    component: RevueRecherchePage
  },
  {
    path: '/comites',
    name: 'comites',
    component: Comites
  },
  {
    path: '/revue-recherche/:slug',
    name: 'revue-recherche-one',
    component: RevueRecherchePageOne
  },
  {
    path: '/numero',
    name: 'numero',
    component: Numero
  },
  {
    path: '/numero/:slug',
    name: 'numero-one',
    component: OneNumero
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/:catchAll(.*)',
    redirect: { name: 'home' }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
