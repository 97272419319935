<template>
    <CoverHeaderRevue />
    <Navbar />
    <div class="container">
      <div class="contenu policear">
        <h3 class="title mb-5 text-center">معايير النشر في مجلة بحوث</h3>
        <p>تخضع كل النصوص المقترحة على المجلة للقراءة، من طرف باحثين مختصين، في احترام
تام لقواعد السرية.</p>
<p>وينبغي للمؤلفين احترام الشروط التالية:</p>
<ul>
  <li> ألا يكون المقال قد سبق نشره
</li>
  <li> أن يحتوي المقال على اسم المؤلف وصفته والمؤسسة التي ينتمي لها وبريده اإللكتروني.
</li>
  <li> يرسل المقال/ البحث عن طريق البريد اإللكتروني للمجلة
</li>
  <li> يكتب المقال بالمواصفات التالية:
</li>
  <li class="det_police">- حجم الخط للنصوص العربية/16:(Majalla Sakkal)
</li>
  <li class="det_police">- Police en français : 12/Cambria, interligne 
</li>
  <li class="det_police">- ألا تزيد مجموع صفحات المقال/البحث عن 02-15 صفحة بما في ذلك المالحق والمراجع.
</li>
  <li class="det_police">- يتم توثيق وكتابة المراجع داخل النص حسب نظام APA يكتب اسم المؤلف، سنة النشر، 
</li>
  <li class="det_police">- الصفحة أو الصفحات، ويتم ذلك بين قوسين  
</li>
  <li class="det_police">- تقبل المقاالت/البحوث المحررة باللغات العربية والفرنسية واإلنجليزية 
</li>
<li class="det_police">- يرفق كل مقال/ بحث بملخص وجيز لموضو ع المقال، مبينا هدفه، والمنهجية المتبعة، وأهم 
</li>
</ul>
<p>نتائجه مع الإشارة إلى المفاهيم أو المصطلحات الرئيسة. ويترجم الملخص الى اللغة
اإلنجليزية.</p>
      </div>
    </div>
    <Footer />
</template>
<style scoped>
.contenu {
  text-align: right;
  direction:rtl;
}
ul li, p {
  font-size: larger;
}
ul li {
  margin-bottom: 13px;
}
.det_police {
    margin-right: 25px;
    list-style: none;
}
.title {
    background: darkseagreen;
    padding: 15px;
    border-radius: 9px;
    color: white;
}
@media (min-width: 1200px) {
  /* Styles pour les écrans larges */
}
@media (min-width: 768px) and (max-width: 1199px) {
  /* Styles pour les tablettes */
}
@media (max-width: 767px) {
  ul li, p {
    text-align: justify;
}
.det_police {
    margin-right: 0;
}
}



</style>