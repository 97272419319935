<template>
    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 contenu-top p-3 text-center">
      <img :src="imagePath + 'cover-header-02.jpg'" class="cover-login-apogee" alt="Cover Login Apogee">
    </div>
</template>
<script>
export default {
  data() {
    return {
        //
    };
  },
  methods: {
    //
  },
  computed: {
    imagePath() {
      return this.$store.state.imagePath;
    },
  },
  mounted() {
  },
};
</script>
  
<style scoped>
.cover-login-apogee {
  width: 80%;
}
@media (min-width: 1200px) {
  /* Styles pour les écrans larges */
}
@media (min-width: 768px) and (max-width: 1199px) {
  /* Styles pour les tablettes */
}
@media (max-width: 767px) {
    .cover-login-apogee {
      width: 100%;
    }
    
}
</style>
  