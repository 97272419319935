import axios from "axios";

const API_BASE_URL = "/conferences";
const MESSAGES_SUCCES = {
  creerUtilisateur: "créé avec succès",
  miseAJour: "Mise à jour effectuée",
};

export default {
  namespaced: true,
  state: {
    data: [],
    dataOne: {},
    currentPage: 1,
    totalPages: 0,
    isLoading: false,
  },
  mutations: {
    setCurrentPage(state, currentPage) {
      state.currentPage = currentPage;
  },
  setTotalPages(state, totalPages) {
      state.totalPages = totalPages;
  },
    setData(state, data) {
      state.data = data;
    },
    setOneData(state, dataOne) {
      state.dataOne = dataOne;
    },
    setLoading(state, isLoading) {
      state.isLoading = isLoading;
    },
  },
  getters: {
    getData: (state) => state.data,
    getCurrentPage: (state) => state.currentPage,
    getTotalPages: (state) => state.totalPages,
    isLoading: (state) => state.isLoading,
  },  
  actions: {
    async fetch({ commit, state }, params) {
      commit('setLoading', true);
      try {
        const response = await axios.get(
          `${API_BASE_URL}?page=${state.currentPage}&search=${params || ""}`
        );
        commit("setData", response.data);
        if(response.data.meta && response.data.meta.last_page) {
          commit("setTotalPages", response.data.meta.last_page);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        commit('setLoading', false); // Fin du chargement
      }
    },
    async fetchOne({ commit, state }, params) {
      commit('setLoading', true);
      try {
        const response = await axios.get(`${API_BASE_URL}/${params}`);
        const item = response.data.data;
      commit('setOneData', item);
      return { success: true, response };
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        commit('setLoading', false); // Fin du chargement
      }
    },
    changePage({ commit, dispatch }, newPage) {
        commit('setCurrentPage', newPage);
        dispatch('fetch');
    },
    async store({ commit, dispatch }, params) {
        try {
            let formData = new FormData();
            formData.append('titre', params.titre);
            formData.append('fichier', params.fichier);
            formData.append('conferencier', params.conferencier);
            formData.append('annee', params.annee);
            
            const response = await axios.post(API_BASE_URL, formData);
            commit("setData", response.data);
            dispatch("showSuccessMessage", MESSAGES_SUCCES.creerUtilisateur);
            return { success: true, response };
          } catch (error) {
            console.error(error);
          }
    },
    showSuccessMessage(_, message) {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: message,
        showConfirmButton: false,
        timer: 1500,
      });
    },  
    async update({ dispatch }, params) {
      try {
            let formData = new FormData();
            formData.append('titre', params.titre);
            formData.append('fichier', params.fichier);
            formData.append('conferencier', params.conferencier);
            formData.append('annee', params.annee);

        const response = await axios.post(`${API_BASE_URL}/${params.id}`,formData);
        dispatch("showSuccessMessage", MESSAGES_SUCCES.miseAJour);
        return { success: true, response };
      } catch (error) {
        console.error(error);
      }
    },
    async destroy({ dispatch }, params) {
      try {
        const result = await Swal.fire({
          title: "Êtes-vous sûr(e) ?",
          text: "Vous ne pourrez pas revenir en arrière !",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Oui, supprimez-le !",
        });
        if (result.isConfirmed) {
          await axios.delete(`${API_BASE_URL}/${params}`);
          Swal.fire("Supprimé !", "Votre fichier a été supprimé.", "success");
        }
        return { success: true, result };
      } catch (error) {
        console.error(error);
      }
    },
  },
  modules: {},
};
