<template>
  <div class="wrapper">     
  <Nav />
  <Aside />
  <div class="content-wrapper" @click="handleOutsideClick">
<div class="content">
  <div class="container-fluid table-crud">
      <div class="container-fluid">
<div class="table-responsive">
  <div class="table-wrapper">
    <div class="table-title">
      <div class="row">
        <div class="col-sm-5 text-left"> 
          <h2>Liste <b></b></h2>
        </div>
        <div class="col-sm-3">
          <input v-model="search" style="padding: 16px !important;font-size: x-small;" type="search" class="form-control border-0 bg-white" id="search-input" placeholder="Recherche par nom & prénom">
        </div>
        <div class="col-sm-4">
          <a href="#addEmployeeModal" class="btn btn-success" data-toggle="modal"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-square ml-1" viewBox="0 0 16 16">
  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
</svg> <span>Ajouter</span></a>
        </div>
      </div>
    </div>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>Nom & Prenom</th>
          <th>Fonction</th>
          <th>Categorie</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in getData.data">
          <td>{{ item.nom_prenom?.toUpperCase() }}</td>
          <td>{{ item.fonction?.toUpperCase() }}</td>
          <td>{{ item.category?.titre.toUpperCase() }}</td>
          <td>
            <a href="#editEmployeeModal" @click="OneFetch(item.id)" class="edit mr-1" data-toggle="modal"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
              <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
              <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
            </svg></a>
                        <a href="#deleteEmployeeModall" @click="submitDestroy(item.id)" class="delete" data-toggle="modal"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
              <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
            </svg></a>
          </td>
        </tr>



      </tbody>
    </table>
    <div class="clearfix mt-5 text-right">
      <Pagination
            :current-page="getCurrentPage"
            :total-pages="getTotalPages"
            @page-changed="PageChange"
      />
    </div>
  </div>
</div>        
</div>
</div><!-- /.container-fluid -->
</div>
<!-- /.content -->
</div>
  <!-- /.content-wrapper -->
  <FooterBack />
  </div>




  <div id="addEmployeeModal" class="modal fade" ref="modal">
    <div class="modal-dialog">
    <div class="modal-content">
        <form @submit.prevent="submit">
    <div class="modal-header">
        <h4 class="modal-title">Ajouter</h4>
        <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label>Nom & Prénom</label>
            <input type="text" class="form-control" v-model="data.nom_prenom" @blur="v$.data.nom_prenom.$touch" :class="{ error: v$.data.nom_prenom.$errors.length }">
            <div class="input-errors" v-for="error of v$.data.nom_prenom.$errors" :key="error.$uid">
                <span class="error-msg">{{ error.$message }}</span>
            </div>
        </div>
        <div class="form-group">
            <label>Fontion</label>
            <input type="text" class="form-control" v-model="data.fonction" @blur="v$.data.fonction.$touch" :class="{ error: v$.data.fonction.$errors.length }">
            <div class="input-errors" v-for="error of v$.data.fonction.$errors" :key="error.$uid">
                <span class="error-msg">{{ error.$message }}</span>
            </div>
        </div>
        <div class="form-group">
          <label>Catégorie</label>
          <select v-model="data.category_id" class="form-select" aria-label="Default select example">
            <option value="" disabled selected>choisi le ...</option>
  <option v-for="item in getDataCategory.data" :value="item.id">{{ item.titre }}</option>
</select>
        </div>
    </div>
    <div class="modal-footer">
        <input ref="removeButton" type="button" class="btn btn-default" style="background: beige;!important" data-dismiss="modal" value="Annuler">
        <input type="submit" class="btn btn-success" :disabled="!isAllFieldsFilled" value="Ajouter">  
    </div>
    </form>

    </div>
    </div>
</div>


<div id="editEmployeeModal" class="modal fade" ref="modal">
<div class="modal-dialog">
  <div class="modal-content">
    <form @submit.prevent="submitUpdate">
  <div class="modal-header">
      <h4 class="modal-title">Modifier</h4>
      <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
  </div>
  <div class="modal-body">
    <div class="form-group">
            <label>Nom & Prénom</label>
            <input type="text" class="form-control" v-model="data.nom_prenom" @blur="v$.data.nom_prenom.$touch" :class="{ error: v$.data.nom_prenom.$errors.length }">
            <div class="input-errors" v-for="error of v$.data.nom_prenom.$errors" :key="error.$uid">
                <span class="error-msg">{{ error.$message }}</span>
            </div>
        </div>
        <div class="form-group">
            <label>Fontion</label>
            <input type="text" class="form-control" v-model="data.fonction" @blur="v$.data.fonction.$touch" :class="{ error: v$.data.fonction.$errors.length }">
            <div class="input-errors" v-for="error of v$.data.fonction.$errors" :key="error.$uid">
                <span class="error-msg">{{ error.$message }}</span>
            </div>
        </div>
        <div class="form-group">
          <select v-model="data.category_id" class="form-select" aria-label="Default select example">
            <option value="" disabled selected>choisi...</option>
  <option v-for="item in getDataCategory.data" :value="item.id">{{ item.titre }}</option>
</select>
        </div>

  </div>
  <div class="modal-footer">
      <input ref="removeButtonUpdate" type="button" style="background: beige;!important" class="btn btn-default" data-dismiss="modal" value="Annuler">
      <input type="submit" class="btn btn-success" value="Modifier">  
  </div>
</form>

  </div>
</div>
</div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { mapGetters, mapActions } from "vuex";
const Path = "Comite/";
const PathCategory = "Category/";
export default {
data() {
  return {
    changePassword: true,
    search: '',
    v$: useVuelidate(),
      data: {
        id: '',
        nom_prenom: '',
        fonction: '',
        category_id: '',
      },
      isAllFieldsFilled: false,
  };
},
validations() {
  return {
  data: {
    nom_prenom: { required },
    fonction: { required },
    category_id: { required }
  }
}
},
computed: {
  ...mapGetters({
    getData: Path + "getData",
    getDataCategory: PathCategory + "getDataAll",
    getCurrentPage: Path + "getCurrentPage",
    getTotalPages: Path + "getTotalPages",
  }),
  imagePath() {
    return this.$store.state.imagePath;
  }
},
watch: {
    search: {
      handler(newSearch) {
        const searchTerm = newSearch || '';
        this.$store.dispatch(Path + 'fetch', searchTerm);
      },
      immediate: true,
    },
    data: {
      handler: 'checkAllFields',
      deep: true,
    },
  },
methods: {
    checkAllFields() {
        this.isAllFieldsFilled = this.data.nom_prenom && this.data.fonction && this.data.category_id
    },
  ...mapActions({
    fetch: Path + "fetch",
    fetchCategory: PathCategory + "fetchAll",
    fetchOne: Path + "fetchOne",
    store: Path + "store",
    update: Path + "update",
    destroy: Path + "destroy",
    changePage: Path + "changePage"
  }),
  handleOutsideClick(event) {
    const modal = this.$refs.modal; 
    if (modal && !modal.contains(event.target)) {
      this.clearFormData();
    }
  },
  async PageChange(newPage) {
    try {
    const result = await this.changePage(newPage);

  } catch (error) {
    console.log(error)
  }},
  async OneFetch(id) {
    try {
    const result = await this.fetchOne(id);
    const getOneData = result.response.data.data;

    if (result.success) {
        this.data = { ...getOneData };
    }
  } catch (error) {
    console.log(error)
  }
  },
  async submit() {
    try {
    const result = await this.store(this.data);
    if (result.success) {
      this.fetch();
      this.$refs.removeButton.click();
      this.clearFormData();
    } else {
    }
  } catch (error) {
    console.log(error)
  }
  },
  async submitUpdate() {
    if (this.changePassword) {
          this.data.password = '';
      }
    try {
    const result = await this.update(this.data);
    if (result.success) {
      this.fetch();
      this.$refs.removeButtonUpdate.click();
      this.clearFormData();
    } else {
    }
  } catch (error) {
    console.log(error)
  }
  },
  async submitDestroy(data) {
    try {
    const result = await this.destroy(data);
    if (result.success) {
      this.fetch();
    } else {
    }
  } catch (error) {
    console.log(error)
  }
  },
  clearFormData() {
    this.data = {
      id: '',
      nom_prenom: '',
      fonction: '',
      nom_prenom: ''
    };
    this.v$.$reset()
    this.changePassword = true;
  }
},
mounted() {
  this.fetch();
  this.fetchCategory();
  document.body.classList.add('hold-transition', 'sidebar-mini');
},
beforeDestroy() {
  console.log('beforeDestroy');
  document.body.classList.remove('hold-transition', 'sidebar-mini');
},
beforeRouteLeave(to, from, next) {
  console.log('beforeRouteLeave');
  document.body.classList.remove('hold-transition', 'sidebar-mini');
  next();
}
};
</script>
