<template>
  <div class="wrapper">     
  <Nav />
  <Aside />
  <div class="content-wrapper" @click="handleOutsideClick">
<div class="content">
  <div class="container-fluid table-crud">
      <div class="container-fluid">
<div class="table-responsive">
  <div class="table-wrapper">
    <div class="table-title">
      <div class="row">
        <div class="col-sm-5 text-left"> 
          <h2>Liste <b></b></h2>
        </div>
        <div class="col-sm-3">
          <input v-model="search" style="padding: 16px !important;font-size: x-small;" type="search" class="form-control border-0 bg-white" id="search-input" placeholder="Recherche par titre">
        </div>
        <div class="col-sm-4">
          <a href="#addEmployeeModal" class="btn btn-success" data-toggle="modal"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus-square ml-1" viewBox="0 0 16 16">
  <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>
  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
</svg> <span>Ajouter</span></a>
        </div>
      </div>
    </div>
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th>titre</th>
          <th>Coordinateur</th>
          <th>Texte</th>
          <th>Axes</th>
          <th>Contact</th>
          <th>Image</th>
          <th>Date de réception</th>
          <th>Date d'envoi</th>
          <th>Date de publication</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in getData.data">
            <td>{{ item.titre?.length > 30 ? item.titre.substring(0, 30) + '...' : item.titre }}</td>
                      <td>{{ item.coordinateur }}</td>
                      <td>{{ item.text?.length > 30 ? item.text.substring(0, 30) + '...' : item.text }}</td>
                      <td>{{ item.axes?.length > 30 ? item.axes.substring(0, 30) + '...' : item.axes }}</td>
                      <td>{{ item.contact }}</td>
                      <td>
                        <img :src="imagePath+item.image" alt="" style="width: 50px;height: 50px;border-radius: 50%;">
                      </td>
                      <td>{{ formatDate(item.date_reception) }}</td>
                      <td>{{ formatDate(item.date_envoi) }}</td>
                      <td>{{ formatDate(item.date_publication) }}</td>

          <td>
            <a href="#editEmployeeModal" @click="OneFetch(item.slug)" class="edit mr-1" data-toggle="modal"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
  <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5z"/>
</svg></a>
            <a href="#deleteEmployeeModall" @click="submitDestroy(item.id)" class="delete" data-toggle="modal"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
</svg></a>
          </td>
        </tr>



      </tbody>
    </table>
    <div class="clearfix mt-5 text-right">
      <Pagination
            :current-page="getCurrentPage"
            :total-pages="getTotalPages"
            @page-changed="PageChange"
      />
    </div>
  </div>
</div>        
</div>
</div><!-- /.container-fluid -->
</div>
<!-- /.content -->
</div>
  <!-- /.content-wrapper -->
  <FooterBack />
  </div>




  <div id="addEmployeeModal" class="modal fade" ref="modal">
<div class="modal-dialog">
  <div class="modal-content">
    <form @submit.prevent="submit">
  <div class="modal-header">
      <h4 class="modal-title">Ajouter</h4>
      <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
  </div>
  <div class="modal-body">
      <div class="form-group">
          <label>Titre</label>
          <input type="text" class="form-control" v-model="data.titre" @blur="v$.data.titre.$touch" :class="{ error: v$.data.titre.$errors.length }">
          <div class="input-errors" v-for="error of v$.data.titre.$errors" :key="error.$uid">
              <span class="error-msg">{{ error.$message }}</span>
          </div>
      </div>
      <div class="form-group">
          <label>Contact</label>
          <input type="text" class="form-control" v-model="data.contact" @blur="v$.data.contact.$touch" :class="{ error: v$.data.contact.$errors.length }">
          <div class="input-errors" v-for="error of v$.data.contact.$errors" :key="error.$uid">
              <span class="error-msg">{{ error.$message }}</span>
          </div>
      </div>
      <div class="form-group">
          <label>Coordinateur</label>
          <input type="text" class="form-control" v-model="data.coordinateur" @blur="v$.data.coordinateur.$touch" :class="{ error: v$.data.coordinateur.$errors.length }">
          <div class="input-errors" v-for="error of v$.data.coordinateur.$errors" :key="error.$uid">
              <span class="error-msg">{{ error.$message }}</span>
          </div>
      </div>
      <div class="form-group mb-5">
          <label>Image</label>
          <div class="file-upload-container mt-2">
            <label class="custom-button">
              <input type="file" ref="fileInput" @change="handleFileChange" class="input-file" id="file-upload"/>
              Choisir une photo
            </label>
            <img v-if="image" :src="image" class="mt-2" alt="Image téléchargée" style="display: block; max-width: 100%;border-radius: 10px;" />
            <span v-if="image" @click="cancelImage" class="cancel-icon">
              <i class="fas fa-times"></i> <!-- Remplacez fa-times par la classe de l'icône d'annulation de votre choix -->
            </span>
            
          </div>
          <div class="input-errors" v-for="error of v$.data.image.$errors" :key="error.$uid">
                      <span class="error-msg">{{ error.$message }}</span>
                  </div>
        </div>
      <div class="form-group">
          <label>Texte</label>
          <textarea name="" id="" v-model="data.text" class="form-control" cols="30" rows="5"></textarea>
          <div class="input-errors" v-for="error of v$.data.text.$errors" :key="error.$uid">
              <span class="error-msg">{{ error.$message }}</span>
          </div>
      </div>
      <div class="form-group">
          <label>Axes</label>
          <textarea name="" id="" v-model="data.axes" class="form-control" cols="30" rows="5"></textarea>
          <div class="input-errors" v-for="error of v$.data.axes.$errors" :key="error.$uid">
              <span class="error-msg">{{ error.$message }}</span>
          </div>
      </div>
      <div class="form-group">
          <label>Date de réception</label>
          <input type="datetime-local" v-model="data.date_reception" class="form-control">
          <div class="input-errors" v-for="error of v$.data.date_reception.$errors" :key="error.$uid">
              <span class="error-msg">{{ error.$message }}</span>
          </div>
      </div>
      <div class="form-group">
          <label>Date d'envoi</label>
          <input type="datetime-local" v-model="data.date_envoi" class="form-control">
          <div class="input-errors" v-for="error of v$.data.date_envoi.$errors" :key="error.$uid">
              <span class="error-msg">{{ error.$message }}</span>
          </div>
      </div>
      <div class="form-group">
          <label>Date de publication</label>
          <input type="datetime-local" v-model="data.date_publication" class="form-control">
          <div class="input-errors" v-for="error of v$.data.date_publication.$errors" :key="error.$uid">
              <span class="error-msg">{{ error.$message }}</span>
          </div>
      </div>
  </div>
  <div class="modal-footer">
      <input ref="removeButton" type="button" style="background: beige;!important" class="btn btn-default" data-dismiss="modal" value="Annuler">
      <input type="submit" class="btn btn-success"  :disabled="!isAllFieldsFilled" value="Ajouter">  
  </div>
</form>

  </div>
</div>
</div>
<div id="editEmployeeModal" class="modal fade" ref="modal">
<div class="modal-dialog">
  <div class="modal-content">
    <form @submit.prevent="submitUpdate">
  <div class="modal-header">
      <h4 class="modal-title">Modifier</h4>
      <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
  </div>
  <div class="modal-body">
      <div class="form-group">
          <label>Titre</label>
          <input type="text" class="form-control" v-model="data.titre" @blur="v$.data.titre.$touch" :class="{ error: v$.data.titre.$errors.length }">
          <div class="input-errors" v-for="error of v$.data.titre.$errors" :key="error.$uid">
              <span class="error-msg">{{ error.$message }}</span>
          </div>
      </div>
      <div class="form-group">
          <label>Contact</label>
          <input type="text" class="form-control" v-model="data.contact" @blur="v$.data.contact.$touch" :class="{ error: v$.data.contact.$errors.length }">
          <div class="input-errors" v-for="error of v$.data.contact.$errors" :key="error.$uid">
              <span class="error-msg">{{ error.$message }}</span>
          </div>
      </div>
      <div class="form-group">
          <label>Coordinateur</label>
          <input type="text" class="form-control" v-model="data.coordinateur" @blur="v$.data.coordinateur.$touch" :class="{ error: v$.data.coordinateur.$errors.length }">
          <div class="input-errors" v-for="error of v$.data.coordinateur.$errors" :key="error.$uid">
              <span class="error-msg">{{ error.$message }}</span>
          </div>
      </div>
      <div class="form-group mb-5">
          <label>Image</label>
          <div class="file-upload-container mt-2">
            <label class="custom-button">
              <input type="file" ref="fileInput" @change="handleFileChange" class="input-file" id="file-upload"/>
              Choisir une photo
            </label>
            <img v-if="image" :src="image" class="mt-2" alt="Image téléchargée" style="display: block; max-width: 100%;border-radius: 10px;" />
      <img v-if="!image" :src="imagePath+data.image" class="mt-2" alt="Image téléchargée" style="display: block; max-width: 100%;border-radius: 10px;" />


      <span v-if="image" @click="cancelImage" class="cancel-icon">
        <i class="fas fa-times"></i> <!-- Remplacez fa-times par la classe de l'icône d'annulation de votre choix -->
      </span>
            
          </div>
          <div class="input-errors" v-for="error of v$.data.image.$errors" :key="error.$uid">
                      <span class="error-msg">{{ error.$message }}</span>
                  </div>
        </div>
      <div class="form-group">
          <label>Texte</label>
          <textarea name="" id="" v-model="data.text" class="form-control" cols="30" rows="5"></textarea>
          <div class="input-errors" v-for="error of v$.data.text.$errors" :key="error.$uid">
              <span class="error-msg">{{ error.$message }}</span>
          </div>
      </div>
      <div class="form-group">
          <label>Axes</label>
          <textarea name="" id="" v-model="data.axes" class="form-control" cols="30" rows="5"></textarea>
          <div class="input-errors" v-for="error of v$.data.axes.$errors" :key="error.$uid">
              <span class="error-msg">{{ error.$message }}</span>
          </div>
      </div>
      <div class="form-group">
          <label>Date de réception</label>
          <input type="datetime" v-model="data.date_reception" class="form-control">
          <div class="input-errors" v-for="error of v$.data.date_reception.$errors" :key="error.$uid">
              <span class="error-msg">{{ error.$message }}</span>
          </div>
      </div>
      <div class="form-group">
          <label>Date d'envoi</label>
          <input type="datetime" v-model="data.date_envoi" class="form-control">
          <div class="input-errors" v-for="error of v$.data.date_envoi.$errors" :key="error.$uid">
              <span class="error-msg">{{ error.$message }}</span>
          </div>
      </div>
      <div class="form-group">
          <label>Date de publication</label>
          <input type="datetime" v-model="data.date_publication" class="form-control">
          <div class="input-errors" v-for="error of v$.data.date_publication.$errors" :key="error.$uid">
              <span class="error-msg">{{ error.$message }}</span>
          </div>
      </div>
  </div>
  <div class="modal-footer">
      <input ref="removeButtonUpdate" type="button" style="background: beige;!important" class="btn btn-default" data-dismiss="modal" value="Annuler">
      <input type="submit" class="btn btn-success" value="Modifier">  
  </div>
</form>

  </div>
</div>
</div>
<!--<div id="editEmployeeModal" class="modal fade" ref="modal">
<div class="modal-dialog">
  <div class="modal-content">
    <form @submit.prevent="submitUpdate">
  <div class="modal-header">
      <h4 class="modal-title">Modifier Utilisateur</h4>
      <button type="button" class="close" data-dismiss="modal" aria-hidden="true">&times;</button>
  </div>
  <div class="modal-body">
      <div class="form-group">
          <label>Nom</label>
          <input type="text" class="form-control" v-model="data.nom_prenom" @blur="v$.data.nom_prenom.$touch" :class="{ error: v$.data.nom_prenom.$errors.length }">
          <div class="input-errors" v-for="error of v$.data.nom_prenom.$errors" :key="error.$uid">
              <span class="error-msg">{{ error.$message }}</span>
          </div>
      </div>

   
 
   
      <div class="form-group">
          <label>Email</label>
          <input type="email" class="form-control" v-model="data.email" @blur="v$.data.email.$touch" :class="{ error: v$.data.email.$errors.length }">
          <div class="input-errors" v-for="error of v$.data.email.$errors" :key="error.$uid">
              <span class="error-msg">{{ error.$message }}</span>
          </div>
      </div>
      <div class="form-group">
          <label>Statut</label>
          <select class="form-control" v-model="data.status" @blur="v$.data.status.$touch" :class="{ error: v$.data.status.$errors.length }">
            <option value="" disabled selected>Choisissez le Statut</option>
              <option value="active">Actif</option>
              <option value="inactive">Inactif</option>
          </select>
          <div class="input-errors" v-for="error of v$.data.status.$errors" :key="error.$uid">
              <span class="error-msg">{{ error.$message }}</span>
          </div>
      </div>
      <div class="form-group">
          <label>Rôle</label>
          <select class="form-control" v-model="data.role" @blur="v$.data.role.$touch" :class="{ error: v$.data.role.$errors.length }">
            <option value="" disabled selected>Choisissez le rôle</option>
              <option value="0">Admin</option>
              <option value="1">User</option>
          </select>
          <div class="input-errors" v-for="error of v$.data.role.$errors" :key="error.$uid">
              <span class="error-msg">{{ error.$message }}</span>
          </div>
      </div>
      <button type="button" class="btn btn-info" @click="changePassword = !changePassword">{{ changePassword ? 'Changer mot de passe' : 'Annuler' }}</button>
<Transition>
<div class="form-group" v-if="!changePassword">
          <label>Mot de passe</label>
          <input type="password" class="form-control" v-model="data.password" @blur="v$.data.password.$touch" :class="{ error: v$.data.password.$errors.length }">
          <div class="input-errors" v-for="error of v$.data.password.$errors" :key="error.$uid">
              <span class="error-msg">{{ error.$message }}</span>
          </div>
      </div>
</Transition>
  </div>
  <div class="modal-footer">
      <input ref="removeButtonUpdate" type="button" style="background: beige;!important" class="btn btn-default" data-dismiss="modal" value="Annuler">
      <input type="submit" class="btn btn-success" value="Modifier">  
  </div>
</form>

  </div>
</div>
</div>-->
</template>
<script>
import { useVuelidate } from '@vuelidate/core'
import { required, email } from '@vuelidate/validators'
import { mapGetters, mapActions } from "vuex";
const Path = "Revue/";
export default {
data() {
  return {
    changePassword: true,
    search: '',
    image: '',
    v$: useVuelidate(),
      data: {
        id: '',
        image: '',
          titre: '',
          contact: '',
          slug: '',
          axes: '',
          coordinateur: '',
          text: '',
          date_reception: '',
          date_envoi: '',
          date_publication: '',
      },
      isAllFieldsFilled: false,
  };
},
validations() {
  return {
  data: {
    image: { required },
    titre: { required },
    contact: { required },
    slug: { required },
    axes: { required },
    coordinateur: { required },
    text: { required },
    date_reception: { required },
    date_envoi: { required },
    date_publication: { required },
  }
}
},
computed: {
  ...mapGetters({
    getData: Path + "getData",
    getCurrentPage: Path + "getCurrentPage",
    getTotalPages: Path + "getTotalPages",
  }),
  imagePath() {
    return this.$store.state.imagePath;
  }
},
watch: {
    search: {
      handler(newSearch) {
        const searchTerm = newSearch || '';
        this.$store.dispatch(Path + 'fetch', searchTerm);
      },
      immediate: true,
    },
    data: {
      handler: 'checkAllFields',
      deep: true,
    },
  },
methods: {
    handleFileChange(event) {
      this.data.image = event.target.files[0];
      const file = event.target.files[0]; 
      if (file) {
        this.displayImage(file);
      }
    },
    displayImage(file) {
      // Code pour afficher l'image téléchargée
      const reader = new FileReader();
      reader.onload = (e) => {
        this.image = e.target.result;
      };
      reader.readAsDataURL(file);
    },
    cancelImage() {
      this.data.image = '';
      this.image = null;
    },






    formatDate(dateString) {
    const months = [
      'janvier', 'février', 'mars', 'avril', 'mai', 'juin',
      'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'
    ];
    const date = new Date(dateString);
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return `${day} ${months[monthIndex]} ${year}`;
  },
    checkAllFields() {
        this.isAllFieldsFilled = this.data.image && this.data.titre && this.data.coordinateur && this.data.text && this.data.axes && this.data.contact
        && this.data.date_reception && this.data.date_envoi && this.data.date_publication
    },
  ...mapActions({
    fetch: Path + "fetch",
    fetchOne: Path + "fetchOne",
    store: Path + "store",
    update: Path + "update",
    destroy: Path + "destroy",
    changePage: Path + "changePage"
  }),
  handleOutsideClick(event) {
    const modal = this.$refs.modal; 
    if (modal && !modal.contains(event.target)) {
      this.clearFormData();
    }
  },
  async PageChange(newPage) {
    try {
    const result = await this.changePage(newPage);

  } catch (error) {
    console.log(error)
  }},
  async OneFetch(slug) {
    try {
    const result = await this.fetchOne(slug);
    const getOneData = result.response.data.data;

    if (result.success) {
        this.data = { ...getOneData };
    }
  } catch (error) {
    console.log(error)
  }
  },
  async submit() {
    try {
    const result = await this.store(this.data);
    if (result.success) {
      this.fetch();
      this.$refs.removeButton.click();
      this.clearFormData();
    } else {
    }
  } catch (error) {
    console.log(error)
  }
  },
  async submitUpdate() {
    if (this.changePassword) {
          this.data.password = '';
      }
    try {
    const result = await this.update(this.data);
    if (result.success) {
      this.fetch();
      this.$refs.removeButtonUpdate.click();
      this.clearFormData();
    } else {
    }
  } catch (error) {
    console.log(error)
  }
  },
  async submitDestroy(data) {
    try {
    const result = await this.destroy(data);
    if (result.success) {
      this.fetch();
    } else {
    }
  } catch (error) {
    console.log(error)
  }
  },
  clearFormData() {
    this.data = {
        id: '',
        image: '',
          titre: '',
          slug: '',
          coordinateur: '',
          axes: '',
          text: '',
          date_reception: '',
          date_envoi: '',
          date_publication: '',
    };
    this.v$.$reset()
    this.changePassword = true;
    this.cancelImage()
  }
},
mounted() {
  this.fetch();
  document.body.classList.add('hold-transition', 'sidebar-mini');
},
beforeDestroy() {
  console.log('beforeDestroy');
  document.body.classList.remove('hold-transition', 'sidebar-mini');
},
beforeRouteLeave(to, from, next) {
  console.log('beforeRouteLeave');
  document.body.classList.remove('hold-transition', 'sidebar-mini');
  next();
}
};
</script>
