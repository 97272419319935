<template>
<div v-if="!isLoading">
<CoverHeaderRevue />
  <Navbar />
  <div class="text-page container text-start text-start-ar">
    <div v-if="isArabic(revue.titre)" class="title-top policear">
      {{ revue.titre }}
    </div>
    <div v-else class="title-top policefr">
      {{ revue.titre }}
    </div>
    <p
      v-if="isArabic(revue.titre)"
      dir="rtl"
      class="text-start-ar policear"
      v-html="revue.text"
    >
    </p>
    <p v-else class="policefr" v-html="revue.text"></p>
    <p
      v-if="isArabic(revue.titre)"
      dir="rtl"
      class="text-start-ar policear"
      v-html="revue.axes"
    >
    </p>
    <p v-else class="policefr" v-html="revue.axes"></p>
    
    

    <div
      v-if="isArabic(revue.titre)"
      class="deadlines policear"
      style="text-align: right;"
    >
      <span>
        : المواعيد النهائية</span
      >
      <ul style="list-style: none" class="policear">
        <li>
          استقبال المقترحات :
          <span style="background: none; font-weight: bold; color: black">{{
            formatDateArab(revue.date_reception)
          }}</span>
        </li>
        <li>
          إرسال المقالات :<span
            style="background: none; font-weight: bold; color: black"
            >{{ formatDateArab(revue.date_envoi) }}</span
          >
        </li>
        <li>
          النشر   :
          <span style="background: none; font-weight: bold; color: black">{{
            formatDateArab(revue.date_publication)
          }}</span>
        </li>
      </ul>
    </div>

    <div v-else class="deadlines policefr">
      <span style="border-radius: 20px; padding: 9px 17px">Délais :</span>
      <ul>
        <li>
          Réception des propositions :
          <span style="background: none; font-weight: bold; color: black">{{
            formatDate(revue.date_reception)
          }}</span>
        </li>
        <li>
          Envois des articles :
          <span style="background: none; font-weight: bold; color: black">{{
            formatDate(revue.date_envoi)
          }}</span>
        </li>
        <li>
        Publication :
          <span style="background: none; font-weight: bold; color: black">{{
            formatDate(revue.date_publication)
          }}</span>
        </li>
      </ul>
    </div>
    <div
      class="contact"
      style="
        font-size: 26px;
        text-align: right;
      "
      v-if="isArabic(revue.titre)"
    >
      {{ revue.contact
      }}<span style="font-size: 26px">
        : للتواصل
      </span>
    </div>
    <div class="contact policefr" v-else>
      <span style="display: block;">CONTACT :</span> <span class="email-contact">{{ revue.contact }}.</span>
    </div>
  </div>
  <Footer />
</div>
  <div v-else>
    <loding />
  </div>

</template>
<script>
import { mapActions, mapGetters  } from "vuex";
const Path = "Revue/";
export default {
  data() {
    return {
      revue: [],
    };
  },
  watch: {
    "$route.params.slug": {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.OneFetch();
        }
      },
    },
  },
  methods: {
    isArabic(text) {
      const arabicRegex = /[\u0600-\u06FF]/;
      return arabicRegex.test(text);
    },
    ...mapActions({
      fetchOne: Path + "fetchOne",
    }),
    async OneFetch() {
      const slug = this.$route.params.slug;
      try {
        const result = await this.fetchOne(slug);
        this.revue = result.response.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    formatDate(dateString) {
      const months = [
        "janvier",
        "février",
        "mars",
        "avril",
        "mai",
        "juin",
        "juillet",
        "août",
        "septembre",
        "octobre",
        "novembre",
        "décembre",
      ];
      const date = new Date(dateString);
      const day = date.getDate();
      const monthIndex = date.getMonth();
      const year = date.getFullYear();

      return `${day} ${months[monthIndex]} ${year}`;
    },
    formatDateArab(dateString) {
      const months = [
        "يناير",
        "فبراير",
        "مارس",
        "أبريل",
        "ماي",
        "يونيو",
        "يوليوز",
        "غشت",
        "شتنبر",
        "أكتوبر",
        "نونبر",
        "دجنبر",
      ];
      const date = new Date(dateString);
      const day = date.getDate();
      const monthIndex = date.getMonth();
      const year = date.getFullYear();

      return `${day} ${months[monthIndex]} ${year}`;
    },
  },
  computed: {
    ...mapGetters({
      isLoading: Path + "isLoading"
    })
  },
  mounted() {
    this.OneFetch();
  },
};
</script>
<style scoped>
.title-top {
  text-align: center;
  background: cadetblue;
  padding: 13px;
  color: white;
  font-size: 25px;
  margin-bottom: 29px;
}
p {
  text-align: justify;
  line-height: 44px;
  font-size: 22px;
}
.propositions {
  background: var(--bs-cyan);
  color: white;
  padding: 9px;
  margin-bottom: 11px;
  padding: 7px 20px;
  margin-bottom: 19px;
  text-align: right;
  font-size: 26px;
  border-radius: 20px;
}
.deadlines {
  font-size: 23px;
        border-radius: 20px;
}
.deadlines span {
  background: coral;
  color: white;
  padding: 7px;
}
.deadlines ul li {
  margin-top: 16px;
  color: #000000b3;
}
.contact span {
  font-weight: bold;
  font-size: 18px;
}

@media (min-width: 1200px) {
  /* Styles pour les écrans larges */
}
@media (min-width: 768px) and (max-width: 1199px) {
  /* Styles pour les tablettes */
}
@media (max-width: 767px) {
  p {
    line-height: 24px;
    font-size: 13px;
  }
  .propositions {
    font-size: small;
    text-align: left;
  }
  .deadlines ul li {
    font-size: 15px;
}
.deadlines span {
    font-size: 14px;
}
}





</style>
