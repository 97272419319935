<template>
    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-dark">
        <!-- Control sidebar content goes here -->
        <div class="p-3">
        <h5>Title</h5>
        <p>Sidebar content</p>
        </div>
    </aside>
    <!-- /.control-sidebar -->

    <!-- Main Footer -->
    <footer class="main-footer">
        <!-- To the right -->
        <div class="float-right d-none d-sm-inline">
        Anything you want
        </div>
        <!-- Default to the left -->
        <strong>Droits d'auteur &copy; 2024-2025 Par <a href="https://www.linkedin.com/in/hamza-chaouni-bb26831a9">Hamza CHAOUNI</a>.</strong> Tous droits réservés.
    </footer>
</template>