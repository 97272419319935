<template>
  <div v-if="!isLoading">
    <CoverHeaderColloques />
  <Navbar />

  <div class="container">
    <div class="row pt-0 pb-0">
      <div class="col-md-12 text-center">
        <div class="text-appel policefr"
        >
          مؤلفات <br /> ( ... ندوات، مؤلف جماعي )
          <br />
          COLLOQUES, LIVRES COLLECTIFS, ...
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div v-for="item in getData.data" :key="item.slug" class="col-md-6">
        <a
        :href="imagePath + item.fichier"
          v-if="isArabic(item.titre)"
          class="text-decoration-none policear" 
        >
          <div class="card dark">
            <div class="card-body">
              <div class="cta-section">
                <div></div>

                <a
                :href="imagePath + item.fichier"
                  class="btn btn-light"
                  >PDF<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-pdf ml-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z"/>
</svg></a
                >
              </div>
              <div class="text-section text-end">
                <h6 class="card-title mb-0">
                  {{
                    item.titre
                  }}
                </h6>
                <p class="card-text">
                  {{ item.description }}
                </p>
              </div>
            </div>
            <img
              :src="imagePath + '2298249.png'"
              class="card-img-top"
              alt="..."
            /></div
        ></a>


        <a
        v-else
        :href="imagePath + item.fichier"
          class="text-decoration-none policefr"
          ><div class="card dark">
            <img :src="imagePath + '2298249.png'" class="card-img-top" alt="..." />
            <div class="card-body">
              <div class="text-section">
                <h6 class="card-title mb-0">
                  {{
                    item.titre
                  }}
                </h6>
                <p class="card-text">
                  {{ item.description }}
                </p>
              </div>
              <div class="cta-section">
                <div></div>

                <a
                :href="imagePath + item.fichier"
                  class="btn btn-light"
                  >PDF<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-filetype-pdf ml-1" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5zM1.6 11.85H0v3.999h.791v-1.342h.803q.43 0 .732-.173.305-.175.463-.474a1.4 1.4 0 0 0 .161-.677q0-.375-.158-.677a1.2 1.2 0 0 0-.46-.477q-.3-.18-.732-.179m.545 1.333a.8.8 0 0 1-.085.38.57.57 0 0 1-.238.241.8.8 0 0 1-.375.082H.788V12.48h.66q.327 0 .512.181.185.183.185.522m1.217-1.333v3.999h1.46q.602 0 .998-.237a1.45 1.45 0 0 0 .595-.689q.196-.45.196-1.084 0-.63-.196-1.075a1.43 1.43 0 0 0-.589-.68q-.396-.234-1.005-.234zm.791.645h.563q.371 0 .609.152a.9.9 0 0 1 .354.454q.118.302.118.753a2.3 2.3 0 0 1-.068.592 1.1 1.1 0 0 1-.196.422.8.8 0 0 1-.334.252 1.3 1.3 0 0 1-.483.082h-.563zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638z"/>
</svg></a>
              </div>
            </div></div
        ></a>
      </div>
    </div>
  </div>

  <Footer />
</div>
<div v-else>
<loding />
  </div>
  
</template>
<script>
import { mapActions, mapGetters } from "vuex";
const Path = "Colloque/";
export default {
  data() {
    return {
      revue: [],
    };
  },
  methods: {
    isArabic(text) {
      const arabicRegex = /[\u0600-\u06FF]/;
      return arabicRegex.test(text);
    },
    ...mapActions({
      fetch: Path + "fetch",
    }),
  },
  computed: {
    ...mapGetters({
      getData: Path + "getData",
      isLoading: Path + "isLoading"
    }),
    imagePath() {
      return this.$store.state.imagePath;
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
<style scoped>
  .text-appel {
            font-weight: bold;
            background: cadetblue;
    padding: 12px;
    border-radius: 33px;
    color: white;
    font-size: 23px;
  }
.title-top h3 {
  background: cadetblue;
  color: white;
  padding: 17px;
  border-radius: 20px;
}
.card {
  max-width: 30em;
  flex-direction: row;
  border: 0;
  box-shadow: 0 7px 7px rgba(0, 0, 0, 0.18);
  margin: 1em auto;
}
.card.dark {
  color: #6c757d;
}
.card.card.bg-light-subtle .card-title {
  color: dimgrey;
}

.card img {
  max-width: 25%;
  margin: auto;
  border-radius: 0.7em;
  padding: 18px;
  /*height: 119px;*/
}
.card-body {
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.text-section {
  max-width: 77%;
}
.cta-section {
  max-width: 40%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
}
.cta-section .btn {
  padding: 0.3em 0.5em;
  /* color: #696969; */
}
.card.bg-light-subtle .cta-section .btn {
  background-color: #898989;
  border-color: #898989;
}

.title-fr {
  font-size: 40px;
}
.title-ar {
  font-size: 33px;
}
.cta-section .btn {
  background: #81c6ef ;
  color: white ;
  font-family: monospace ;
}
.card-title {
  font-weight: bold;
  font-size: 18px;
}
.card-text {
  font-size: 15px;
}
@media screen and (max-width: 475px) {
  .card {
    font-size: 0.9em;
  }
}

@media (min-width: 1200px) {
  /* Styles pour les écrans larges */
}
@media (min-width: 768px) and (max-width: 1199px) {
  /* Styles pour les tablettes */
}
@media (max-width: 767px) {
  .title-top h3 {
    font-size: 16px;
  }
  .text-end,
  .text-start {
    text-align: center;
  }
  .title-fr {
    font-size: 29px;
  }
  .title-ar {
    font-size: 23px;
  }
  .card-title {
    font-size: 16px;
  }
  .card-text {
    font-size: 15px;
  }
  .cta-section {
    display: none;
  }
  .text-section {
    max-width: 100%;
  }
  .card img {
    padding: 13px;
  }
  .card {
    font-size: 0.9em;
    padding-left: 13px;
  }
}
</style>
