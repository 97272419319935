<template>
  <div class="wrapper">     
  <Nav />
  <Aside />
  <!--test-->
  <FooterBack />
  </div>




</template>
<script>

export default {
data() {
  return {
    //
  };
},
computed: {
  //
},
watch: {
  //
  },
methods: {
  //
},
mounted() {
  document.body.classList.add('hold-transition', 'sidebar-mini');
},
beforeDestroy() {
  console.log('beforeDestroy');
  document.body.classList.remove('hold-transition', 'sidebar-mini');
},
beforeRouteLeave(to, from, next) {
  console.log('beforeRouteLeave');
  document.body.classList.remove('hold-transition', 'sidebar-mini');
  next();
}
};
</script>
