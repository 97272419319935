<template>
<div v-if="!isLoading">
<CoverHeaderRevue />
  <Navbar />
  <div class="container">
    <div class="row">
      <div class="col-md-3 mb-4" v-for="item in getData.data">
        <div class="card p-3">
          <router-link
            :to="{ name: 'numero-one', params: { slug: item.slug } }"
          >
            <img :src="imagePath + item.image" class="card-img-top" alt="..." />
          </router-link>
          <div class="card-body">
            <h5 class="card-title">
              <span style="font-weight: bold;margin-bottom: 3px;">Coordination :</span> <br />{{ item.coordinateur }}
            </h5>
            <p class="card-text">N° : {{ item.numero }}</p>
            <div class="icone text-center">
              <router-link
                router-link
                :to="{ name: 'numero-one', params: { slug: item.slug } }"
                class="icone"              >
                Plus
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="margin-left: 5px"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-caret-right-square-fill"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M0 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm5.5 10a.5.5 0 0 0 .832.374l4.5-4a.5.5 0 0 0 0-.748l-4.5-4A.5.5 0 0 0 5.5 4z"
                  />
                </svg>
              </router-link>
            </div>
          </div>
          <div v-if="isArabic(item.title)" class="title-card mt-3 text-center policear" style="color: cadetblue;">{{ item.title }}</div>
          <div v-else class="title-card mt-3 text-center policefr" style="color: cadetblue;">{{ item.title }}</div>
        </div>
      </div>
    </div>
  </div>
  <Footer />
</div>
  <div v-else><loding /></div>
  
</template>
<script>
import { mapActions, mapGetters } from "vuex";
const Path = "Numero/";
export default {
  data() {
    return {
      //
    };
  },
  watch: {
    //
  },
  methods: {
    ...mapActions({
      fetch: Path + "fetch",
    }),
    isArabic(text) {
      const arabicRegex = /[\u0600-\u06FF]/;
      return arabicRegex.test(text);
    },
  },
  computed: {
    ...mapGetters({
      getData: Path + "getData",
      isLoading: Path + "isLoading"
    }),
    imagePath() {
      return this.$store.state.imagePath;
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
<style scoped>
.title-card {
  font-size: 20px;
  line-height: 21px;
}
.card {
  border: none;
  border-radius: 0 !important;
  box-shadow: 0 7px 7px rgba(0, 0, 0, 0.18);
  position: relative;
  height: 500px;
}
img {
  height: 320px;
}
.card-body {
  position: absolute;
  top: -160px;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
}
.card:hover img {
  opacity: 0.4;
}

.card:hover .card-body {
  position: absolute;
  top: 27%;
  opacity: 1;
  background: white;
  margin: 17px;
  visibility: visible;
  border-radius: 5px;
}
.icone {
  background: #81c6ef;
  color: white;
  padding: 6px 10px;
  border-radius: 4px;
  text-decoration: none;
}
@media (min-width: 1200px) {
  /* Styles pour les écrans larges */
}
@media (min-width: 768px) and (max-width: 1199px) {
  /* Styles pour les tablettes */
}
@media (max-width: 767px) {
  img {
    height: 400px;
  }
  .card {
    height: 550px;
}
.title-card {
  margin-top: 20px !important;
}
.card:hover .card-body {
  position: absolute;
  top: 155px;
  opacity: 1;
  background: white;
  margin: 17px;
  visibility: visible;
  border-radius: 5px;
  left: 40px;
}

}
</style>
